import React from 'react';
import { Row, Col } from 'antd';
import { SecurityScanOutlined,  DesktopOutlined, DatabaseOutlined } from "@ant-design/icons";


const items = [
  {
    key: '1',
    icon: <DesktopOutlined />,
    title: 'A Platform for Coffee aficionado',
    content: 'This is a platform for coffee aficianodos. You fill in your coffee preferences and we will match dates with coffee choices..',
  },
  {
    key: '2',
    icon: <SecurityScanOutlined />,
    title: 'Privacy and Security',
    content: 'We understand the importance of privacy and security in the context of dating searches  Coffeewithdate employs security measures to safeguard your personal information, ensuring a safe and confidential space for your journey to find a life partner.',
  },
  {
    key: '3',
    icon: <DatabaseOutlined />,
    title: 'Diverse Profiles, Shared Values',
    content: "Our platform hosts a diverse array of profiles within the community, allowing you to explore a wide range of potential matches while staying rooted in shared cultural values. Whether you're looking for a date or Coffeewithdate provides a space for diverse preferences."
  }
]

function AppAbout() {
  return (
    <>
     <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>About Us</h2>
        </div>
        <div className="contentHolder">
          <p>
          Welcome to CoffeewithDate, a sacred space designed exclusively to embark on the 
          beautiful journey of finding a life partner. We understand the sanctity and significance of finding the right partner, 
          and our platform is crafted with utmost care to honor these traditions. </p>

          <p>
          At CoffeewithDate, we celebrate the rich cultural heritage of marriage, acknowledging the values, traditions, and principles 
          that make marriage successful. Established with a deep respect for the sanctity of marriage, our platform is committed 
          to fostering meaningful connections.
          </p>

          <p>CoffeewithDate goes beyond the conventional approach to matchmaking. Our platform combines traditional values with 
          contemporary technology to facilitate meaningful connections. We recognize that compatibility goes beyond surface-level criteria, 
          and our advanced algorithms are designed to understand the deeper aspects of compatibility unique to each user.
          </p>
        </div>
        <Row gutter={[16, 16]}>   
          {items.map(item => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <div className="icon">
                    {item.icon}
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
        <p>
        We invite you to join our community of like-minded individuals seeking a life partner. Coffeewithdate is more than just a platform; it's a community 
        that understands the sacred nature of marriage.
        </p>
        <p>
        Embark on your journey with Coffeewithdate, where tradition meets technology, and where meaningful connections are nurtured with care and respect.
        </p>
      </div>
    </div>  
    </>
   
  );
}

export default AppAbout;