import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import LikeProfile from "./LikeProfile";
import { useUserData } from "../../hooks/useUsers";
import {
  Row,
  Col,
  Card,
  Button,
  QRCode,
  Typography,
  Modal,
  Table,
  Space,
  Spin,
  Image,
} from "antd";

const { Title } = Typography;

function Profile() {
  const { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [profile, setProfile] = useState({});
  const [user] = useAuthState(auth);

  const { loading, userData } = useUserData(id);

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentHost = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    if (userData) {
      setProfile(userData);
      profile &&
        Object.keys(profile).forEach(
          (k) =>
            (profile[k] === "" || profile[k] === undefined) && delete profile[k]
        );
    } else {
      return;
    }
  }, [user, userData, loading, profile, navigate]);

  // remove blank keys from profile..

  // const contactColumns = {
  //   contactName: "Contact Name",
  //   contactNumber: "Contact #",
  // };

  const basicColumns = {
    username: "UserName",
    firstName: "First Name",
    gender: "Gender",
    maritalStatus: "Marital Status",
    haveKids: "Have Kids",
    wantKids: "Want Kids",
  };

  const backgroundColumns = {
    ethnicity: "Ethnicity",
    politics: "Politics",
    religion: "Religion",
    zodiacSign: "Zodiac Sign",
    education: "Education",
    schoolName: "School Name",
    jobTitle: "Job Title",
    employer: "Employer",
  };

  const generalColumns = {
    smoking: "Smoking",
    tobacco: "Tobacco Products",
    drinking: "Drinking",
    marijuana: "Marijuana",
    diet: "Dietary",
    height: "Height",
    bodyType: "Body Type",
    disability: "Disability",
  };

  const familyColumns = {
    haveKids: "Have Kids",
    wantKids: "Want Kids",
    havePets: "Have Pets",
    haveCar: "Have a Car",
    haveDebt: "Have Debt",
  };

  const titles = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
  ];

  const basicData = Object.keys(basicColumns)
    .map((basic) => ({
      key: basic,
      title: basicColumns[basic],
      value:
        basic === "dateOfBirth"
          ? profile[basic]?.toDate().toDateString()
          : profile[basic],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const backgroundData = Object.keys(backgroundColumns)
    .map((background) => ({
      key: background,
      title: backgroundColumns[background],
      value: profile[background],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const generalData = Object.keys(generalColumns)
    .map((general) => ({
      key: general,
      title: generalColumns[general],
      value: profile[general],
    }))
    .filter(({ key, title, value }) => key && title && value);

  const familyData = Object.keys(familyColumns)
    .map((family) => ({
      key: family,
      title: familyColumns[family],
      value: profile[family],
    }))
    .filter(({ key, title, value }) => key && title && value);

  return (
    <div className="container">
      <Spin spinning={loading}>
        {profile && (
          <Row>
            <Col xs={24}>
              <Card bordered={false}>
                <Button size="small" onClick={() => navigate(-1)}>
                  Go Back
                </Button>
                <br />
                <Row>
                  <Col sm={10} md={10} xl={10} style={{ padding: "20px" }}>
                    <Image.PreviewGroup
                      items={profile && profile.profileImages && profile?.profileImages.map((image) => image.url)}
                    >
                      <Image
                        width={300}
                        height={300}
                        src={
                          profile && profile.profileImages && profile?.profileImages[0]?.url
                        }
                      />
                    </Image.PreviewGroup>
                    <Title level={4}>
                      {profile.firstName} {profile.lastName}
                    </Title>
                  </Col>
                  <Col sm={14} md={14} xl={14}>
                    <Space style={{ marginBottom: "10px" }}>
                      {user && profile.uid && (
                        <LikeProfile
                          id={profile.uid}
                          userId={currentHost.uid}
                        />
                      )}
                      <Button type="primary" onClick={showModal}>
                        {" "}
                        QR Code
                      </Button>
                    </Space>
                    <Modal
                      centered
                      width={300}
                      title="Profile Page"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <QRCode
                        size={240}
                        type="canvas"
                        value={`${currentHost}/profile-page/${profile.id}`}
                      />
                    </Modal>
                    <Card title={<Title level={5}>Basic Information</Title>}>
                      <Table
                        size="small"
                        columns={titles}
                        dataSource={basicData}
                        pagination={false}
                        showHeader={false}
                      />
                    </Card>
                    <Card
                      title={<Title level={5}>Background Information</Title>}
                    >
                      <Table
                        size="small"
                        columns={titles}
                        dataSource={backgroundData}
                        pagination={false}
                        showHeader={false}
                      />
                    </Card>
                    {showMore ? (
                      <>
                        <Card title={<Title level={5}>General</Title>}>
                          <Table
                            size="small"
                            columns={titles}
                            dataSource={generalData}
                            pagination={false}
                            showHeader={false}
                          />
                        </Card>
                        <Card
                          title={<Title level={5}>Family Information</Title>}
                        >
                          <Table
                            size="small"
                            columns={titles}
                            dataSource={familyData}
                            pagination={false}
                            showHeader={false}
                          />
                        </Card>
                        <Card title={<Title level={5}>Requirements</Title>}>
                          {profile.requirements}
                        </Card>{" "}
                      </>
                    ) : null}
                    <div style={{ justify: "center", align: "middle" }}>
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less.." : "Show more..."}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
}

export default Profile;
