import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  orderBy,
  startAfter,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import { Row, Col, Typography, Spin, Button } from "antd";
import { Empty, Card, Badge } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from "../../hooks/useUsers";
import { getAge } from "../../lib/utils";
const { Meta } = Card;

// function getDate(years) {
//   var date = new Date();
//   date.setFullYear(date.getFullYear() - years);
//   return date;
// }

export default function Suggestions() {
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [profileCount, setProfileCount] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [lastProfile, setLastProfile] = useState(null);
  const [user] = useAuthState(auth);
  const { loading: userLoading, userData } = useUserData(user?.uid);

  useEffect(() => {
    if (userLoading) return;
    if (userData) {
      const profileRef = collection(db, "users");

      const getOppositeGender = userData?.gender === "Male" ? "Female" : "Male";

      let q = query(
        profileRef,
        where("profileCompleted", "==", true),
        where("gender", "==", getOppositeGender),
        where("coffeeFlavors", "array-contains-any", userData.coffeeFlavors),
        orderBy("gender", "desc"),
        limit(10)
      );

      onSnapshot(q, (snapshot) => {
        const profiles = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          // .filter(item => !item.friendRequests?.includes(userData?.uid));
        setProfiles(profiles);
        setLoading(false);
      });
    }
  }, [userLoading, userData, user]);

  const loadMoreProfiles = () => {
    setLoadMoreLoading(true);
    const getOppositeGender = userData?.gender === "Male" ? "Female" : "Male";

    const profileRef = collection(db, "users");

    let q = query(
      profileRef,
      where("profileCompleted", "==", true),
      where("gender", "==", getOppositeGender),
      where("coffeeFlavors", "array-contains-any", userData.coffeeFlavors),
      orderBy("gender", "desc"),
      limit(10),
      startAfter(lastProfile)
    );

    onSnapshot(q, (snapshot) => {
      const moreProfiles = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // .filter(item => !item.friendRequests?.includes(userData?.uid));
      const lastProfile = snapshot.docs[snapshot.docs.length - 1];
      setLastProfile(lastProfile);
      profiles.push(...moreProfiles);
      setProfileCount(moreProfiles.length);
      setLoadMoreLoading(false);
    });
  };

  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container">
        <div className="titleHolder">
          <h2>Profiles</h2>
        </div>
        {loading && <Spin />}
        {profiles && profiles.length > 0 && (
          <>
            <Row gutter={[16, 16]}>
              {profiles.map((profile) => (
                <Col
                  key={profile.id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                >
                  <Link to={`/profile-page/${profile.id}`}>
                    <Badge.Ribbon
                      placement="end"
                      text={`${getAge(profile.dateOfBirth)} yrs`}
                      color="blue"
                    >
                      <Card
                        hoverable
                        cover={
                          <div style={{ overflow: "hidden", height: "240px" }}>
                            <img
                              alt={profile.firstName}
                              style={{ height: "100%", width: "100%" }}
                              src={profile.profileImages[0]?.url}
                            />
                          </div>
                        }
                      >
                        <Meta title={`${profile.firstName}`} />
                        <Typography>
                          {profile.gender} | {profile.maritalStatus} |{" "}
                          {profile.currentResidence}
                        </Typography>
                      </Card>
                    </Badge.Ribbon>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        )}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <Button disabled={profileCount < 6} onClick={loadMoreProfiles}>
            Load more..
          </Button>
        </div>
        {loadMoreLoading && <Spin />}
        {profiles && profiles.length === 0 && (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 120,
            }}
            description={<span>No profiles found</span>}
          ></Empty>
        )}
      </div>
    </div>
  );
}
