import React, { useState } from "react";
import { Timestamp, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { userName, getAge } from "../../lib/utils";
import maleLogo from "../../assets/images/icons8-man-in-tuxedo-48.png";
import femaleLogo from "../../assets/images/icons8-female-48.png";
import kidsLogo from "../../assets/images/icons8-kids-40.png";
import noKidsLogo from "../../assets/images/icons8-no-kids-50.png";

import {
  Space,
  Divider,
  Spin,
  Button,
  Row,
  Col,
  Card,
  Form,
  Radio,
  Checkbox,
  Select,
  Input,
  Typography,
  DatePicker,
  Flex,
  Upload,
  Progress,
  message
} from "antd";
import jsonData from "../../data/data.json";
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

const { Title } = Typography;
const { TextArea } = Input;
const dateFormat = "MM/DD/YYYY";

export default function AddProfile() {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [user] = useAuthState(auth);
  const [progress, setProgress] = useState(0);
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    profileId: "",

    firstName: "",
    gender: "",
    dateOfBirth: "",
    maritalStatus: "",
    currentResidence: "",
    citizenOf: "",
    languages: [],

    // Background Information
    ethnicity: "",
    religion: "",
    zodiacSign: "",
    politics: "",
    education: "",
    schoolName: "",
    employment: "",
    jobTitle: "",
    employer: "",

    coffeeFlavors: [],

    // General Information
    smoking: "",
    tobacco: "",
    drinking: "",
    marijuana: "",
    diet: "",
    height: "",
    bodyType: "",
    disability: "",

    // Finance Information
    haveCar: "",
    haveDebt: "",

    // Family Information
    haveKids: "",
    wantKids: "",
    havePets: "",

    aboutMe: "",
    lookingFor: "",

    images: {},

    userName: "",
    createdAt: Timestamp.now().toDate(),
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRadio = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (name) => (value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, dateString, id) => {
    setFormData({ ...formData, [id]: dayjs(date).toDate() });
  };

  const handleSelect = (name) => (value) => {
    setFormData({ ...formData, [name]: value });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setFormData((prev) => ({
      ...prev,
      images: e?.fileList,
    }));
    return e?.fileList;
  };

  const nextStep = async () => {
    try {
      await form.validateFields();
      setCurrent((current) => {
        return current < 9 ? current + 1 : current;
      });
    } catch { }
  };

  const prevStep = () =>
    setCurrent((current) => (current > 0 ? current - 1 : current));

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const storageImage = async (img) => {
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, `/images/${Date.now()}-${uuidv4()}`);
          const uploadImage = uploadBytesResumable(
            storageRef,
            img.originFileObj
          );

          uploadImage.on(
            "state_changed",
            (snapshot) => {
              const progressPercent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progressPercent);
            },
            (error) => {
              reject(error);
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
                resolve({ uid: uuidv4(), url: downloadURL });
              });
            }
          );
        });
      };

      const profileImages = await Promise.all(
        [...formData.images].map((img) => storageImage(img))
      ).catch((error) => {
        alert(error);
        return;
      });

      const profileDoc = {
        // Basic Information
        profileId: uuidv4(),

        firstName: formData.firstName,
        gender: formData.gender,
        dateOfBirth: formData.dateOfBirth,
        maritalStatus: formData.maritalStatus,
        currentResidence: formData.currentResidence,
        citizenOf: formData.citizenOf,
        languages: formData.languages,

        // Background Information
        ethnicity: formData.ethnicity,
        religion: formData.religion,
        zodiacSign: formData.zodiacSign,
        politics: formData.politics,
        education: formData.education,
        schoolName: formData.schoolName,
        employment: formData.employment,
        jobTitle: formData.jobTitle,
        employer: formData.employer,

        // Coffee Flavors
        coffeeFlavors: formData.coffeeFlavors,
        // General Information
        smoking: formData.smoking,
        tobacco: formData.tobacco,
        drinking: formData.drinking,
        marijuana: formData.marijuana,
        diet: formData.diet,
        height: formData.height,
        bodyType: formData.bodyType,
        disability: formData.disability,

        // Finance Information
        haveCar: formData.haveCar,
        haveDebt: formData.haveDebt,

        // Family Information
        haveKids: formData.haveKids,
        wantKids: formData.wantKids,
        havePets: formData.havePets,

        aboutMe: formData.aboutMe,
        lookingFor: formData.lookingFor,

        profileImages,
        preferences: {},
        createdAt: Timestamp.now().toDate(),
        createdBy: user.displayName,
        userName: userName(formData.gender),
        userId: user.uid,
        profileCompleted: true,
      };

      const userRef = doc(db, "users", user.uid);
      // setDoc(profileRef,  profileDoc)
      updateDoc(userRef, profileDoc)
        .then(() => {
          updateProfile(auth.currentUser, {
            displayName: formData.firstName,
          });
          setLoading(false);
          setProgress(0);
          message.success(`Your profile created successfully.`, 2);
          navigate("/");
        })
        .catch((err) => {
          message.error(`Profile could not be created.`, 2);
        });

      setTimeout(() => {
        form.resetFields();
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(formData);

  return (
    <div className="container">
      <Title
        justify="center"
        align="middle"
        level={3}
        style={{ marginTop: "10px" }}
      >
        Create Profile
      </Title>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="create-profile"
          layout="vertical"
          autoComplete="off"
          onFinish={handleFinish}
        >
          <>
            {current === 0 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Basic Information</Title>}>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please input First Name",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="firstName"
                              placeholder="First Name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Your gender",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="gender"
                              onChange={handleRadio}
                            >
                              <Radio value="Male">
                                {" "}
                                <Space>
                                  <img src={maleLogo} width="20px" alt="Male" />
                                  Male
                                </Space>
                              </Radio>
                              <Radio value="Female">
                                {" "}
                                <Space>
                                  <img
                                    src={femaleLogo}
                                    width="20px"
                                    alt="Female"
                                  />
                                  Female
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marital Status"
                            name="maritalStatus"
                            rules={[
                              {
                                required: true,
                                message: "Your marital status",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("maritalStatus")}
                              placeholder="Marital Status"
                              showSearch
                              options={jsonData.maritals}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateOfBirth"
                            rules={[
                              {
                                validator(_, value) {
                                  console.log("value ", value, value === null);
                                  if (value === null) {
                                    return Promise.reject(
                                      new Error("DOB Required")
                                    );
                                  } else if (value !== "" && getAge(value) < 18) {
                                    return Promise.reject(
                                      new Error("You must be 18+")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                          >
                            <DatePicker
                              format={dateFormat}
                              name="dateOfBirth"
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  "dateOfBirth"
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Current residence"
                            name="currentResidence"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z, ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="currentResidence"
                              placeholder="Residing city"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Citizen of" name="citizenOf">
                            <Select
                              onChange={handleSelect("citizenOf")}
                              placeholder="Citizen Of"
                              showSearch
                              options={jsonData.currencies}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Languages you speak"
                            name="languages"
                          >
                            <Select
                              mode="multiple"
                              onChange={handleSelect("languages")}
                              placeholder="Languages you speak"
                              showSearch
                              options={jsonData.languages}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 1 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card
                      title={<Title level={5}>Background Information</Title>}
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Ethnicity"
                            name="ethnicity"
                            rules={[
                              {
                                required: true,
                                message: "Ethnicity",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("ethnicity")}
                              placeholder="Ethnicity"
                              showSearch
                              options={jsonData.ethnicity}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Politics"
                            name="politics"
                            rules={[
                              {
                                required: true,
                                message: "Politics",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("politics")}
                              placeholder="Politics"
                              showSearch
                              options={jsonData.politics}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Religion"
                            name="religion"
                            rules={[
                              {
                                required: true,
                                message: "Religion",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("religion")}
                              placeholder="Religion"
                              showSearch
                              options={jsonData.religion}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Zodiac Sign" name="zodiacSign">
                            <Select
                              onChange={handleSelect("zodiacSign")}
                              placeholder="zodiacSign"
                              showSearch
                              options={jsonData.zodiacSign}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education"
                            name="Education"
                            rules={[
                              {
                                required: true,
                                message: "Education",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("education")}
                              placeholder="education"
                              showSearch
                              options={jsonData.education}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="School/University"
                            name="schoolName"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input name="schoolName" placeholder="School/University you attended" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Job Title"
                            name="jobTitle"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z., ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="jobTitle"
                              onChange={handleChange}
                              placeholder="Job Title"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Employer Name" name="employer"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z,. ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="employer"
                              onChange={handleChange}
                              placeholder="Employer you work for"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="default" onClick={prevStep} htmlType="button">
                          previous
                        </Button>
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 2 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card
                      title={
                        <Title level={5}>Favorite Coffee Drink(s)</Title>
                      }
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={12} lg={24} xl={24}>
                          <Form.Item
                            name="coffeeFlavors"
                            rules={[
                              {
                                validator(_, value) {
                                  console.log("value ", value);
                                  if (value.length < 3) {
                                    return Promise.reject(
                                      new Error("You must select at least 3 flavors")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}>
                            <Checkbox.Group
                              style={{
                                width: "100%",
                              }}
                              onChange={handleCheckbox("coffeeFlavors")}
                            >
                              <Row gutter={6}>
                                {jsonData.coffeeFlavors.map((flavor) => (
                                  <Col key={flavor.key} xs={12} lg={6} xl={6}>
                                    <Checkbox value={flavor.value}>{flavor.value}</Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="default" onClick={prevStep} htmlType="button">
                          previous
                        </Button>
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 3 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>General Information</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Smoking"
                            name="smoking"
                            rules={[
                              {
                                required: true,
                                message: "Smoking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="smoking"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Tobacco"
                            name="tobacco"
                            rules={[
                              {
                                required: true,
                                message: "Tobacco Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="tobacco"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Drinking"
                            name="drinking"
                            rules={[
                              {
                                required: true,
                                message: "Drinking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="drinking"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Socia lly"> Socially</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marijuana"
                            name="marijuana"
                            rules={[
                              {
                                required: true,
                                message: "Marijuana Habit",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="marijuana"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Sometimes"> Sometimes </Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Dietary Habits"
                            name="diet"
                            rules={[
                              {
                                required: true,
                                message: "Dietary Habits",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Dietary habits"
                              onChange={handleSelect("diet")}
                              options={jsonData.diet}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Height" name="height">
                            <Select
                              showSearch
                              placeholder="Your height"
                              onChange={handleSelect("height")}
                              options={jsonData.height}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Body Type" name="bodyType">
                            <Select
                              showSearch
                              placeholder="Body type"
                              onChange={handleSelect("bodyType")}
                              options={jsonData.bodyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Disability" name="disability">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="disability"
                              onChange={handleRadio}
                            >
                              <Radio value="Normal"> Normal</Radio>
                              <Radio value="Physically Challenged">
                                Physically Challenged
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="default" onClick={prevStep} htmlType="button">
                          previous
                        </Button>
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 4 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card
                      title={
                        <Title level={5}>Family/Finance Information</Title>
                      }
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Kids " name="haveKids">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveKids"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes">
                                {" "}
                                <Space>
                                  <img src={kidsLogo} width="20px" alt="Kids" />
                                  Yes
                                </Space>
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <Space>
                                  <img
                                    src={noKidsLogo}
                                    width="20px"
                                    alt="No Kids"
                                  />
                                  No
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Want Kids " name="wantKids">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="wantKids"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Pets " name="havePets">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="havePets"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left">Finance</Divider>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have a Car " name="haveCar">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveCar"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Debt " name="haveDebt">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveDebt"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="default" onClick={prevStep} htmlType="button">
                          previous
                        </Button>
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 5 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Upload Photos</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={24} xl={24}>
                          <Form.Item
                            name="profileImages"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload at least one photo",
                              },
                            ]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              listType="picture-card"
                              accept="image/*"
                            >
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button"
                              >
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                              </button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="default" onClick={prevStep} htmlType="button">
                          previous
                        </Button>
                        <Button type="primary" onClick={nextStep} htmlType="button">
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 6 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>About Self</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Me"
                            name="aboutMe"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about yourself",
                              },
                            ]}
                          >
                            <TextArea
                              showCount
                              name="aboutMe"
                              onChange={handleChange}
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Flex
                  type="flex"
                  justify="center"
                  align="middle"
                  gap="small"
                  wrap="center"
                >
                  <Button type="default" onClick={prevStep} htmlType="button">
                    previous
                  </Button>
                  <Button type="primary" onClick={nextStep} htmlType="button">
                    Next
                  </Button>
                </Flex>
              </>
            ) : current === 7 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Match Requirements</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="Looking for in a Partner"
                            name="lookingFor"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Looking for in a partner",
                              },
                            ]}
                          >
                            <TextArea
                              showCount
                              name="lookingFor"
                              onChange={handleChange}
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    {progress === 0 ? null : <Progress percent={progress} />}
                  </Col>
                </Row>
                <Flex
                  type="flex"
                  justify="center"
                  align="middle"
                  gap="small"
                  wrap="center"
                >
                  <Button type="default" onClick={prevStep} htmlType="button">
                    Previous
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Create Profile
                  </Button>
                </Flex>
              </>
            ) : (
              <>Invalid Step </>
            )}
          </>
        </Form>
      </Spin>
    </div>
  );
}
