import { Collapse } from 'antd';
const { Panel } = Collapse;

function AppFAQ() {
  return (
    <div id="faq" className="block faqBlock">
    <div className="container">
      <div className="titleHolder">
        <h2>Frequently Asked Questions</h2>
      </div>
      <Collapse>
        <Panel header="How do I create a profile on the Coffeewithdate website?" key="2">
          <p>Creating a profile is simple. Signin using email and password or your Google/Github Account, fill in the required details, and provide information about your background, preferences, and what you are looking for in a life partner</p>
        </Panel>
        <Panel header="Can I specify criteria in the search?" key="3">
          <p>Absolutely. Our advanced search filters allow you to specify your preferences, ensuring that you receive match suggestions that align with your background and your coffee flavor preferences .</p>
        </Panel>
        <Panel header="Can I find matches based on educational and professional criteria?" key="5">
          <p>Yes, you can. Our platform allows you to specify educational and professional criteria to find matches that align with your aspirations and preferences.</p>
        </Panel>
        <Panel header="How can I connect with potential matches on the CoffeewithDate website?" key="6">
          <p>You can express interest, send messages, and engage with potential matches through our communication tools. We encourage users to communicate and get to know each other before making important decisions</p>
        </Panel>
        <Panel header="Can I use the Coffeewithdate website on my smartphone?" key="8">
          <p>Yes, you can use our webstie on your smartphone. We are going to offer a mobile application in the future for added convenience.</p>
        </Panel>
        <Panel header="Is customer support available if I have questions or concerns?" key="10">
          <p>Yes, we have a responsive customer support team ready to assist you. Feel free to reach out via the "Contact Us" section, and we'll be happy to address any questions or concerns you may have.</p>
        </Panel>
        <Panel header="How is privacy maintained on the Coffeewithdate  website?" key="11">
          <p>Our platform employs security measures to ensure the confidentiality of user data. You have control over your privacy settings and can choose what information you want to share.</p>
        </Panel>
      </Collapse>    
    </div>
  </div>  
  );
}

export default AppFAQ;