import React, { useState } from "react";
import { useUserData } from "./useUsers";

export const useProfileStatus = (id) => {
  const { loading, userData } = useUserData(id);
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);

  React.useEffect(() => {
    async function getUserDataById() {
      if (loading) return;
      if (userData) {
        setIsProfileCompleted(userData?.isProfileCompleted);
      }
    }
    getUserDataById();
  }, [loading, userData]);

  return { loading, isProfileCompleted };
};

export default useProfileStatus