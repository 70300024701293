import React from 'react';
import { FloatButton } from 'antd';
import { NavLink } from "react-router-dom";

function FooterCopyright() {
  return (
    <div className="footerCopyright">
      <div className="container">
        <div className="copyright">©2024 CoffeewithDate.</div>
        <div className="header separator">
          <nav>
            <ul>
              <li>
                <NavLink style={{color: "white"}} to="/privacy">Privacy</NavLink>
              </li>
              <li>
                <NavLink style={{color: "white"}} to="/terms">Terms</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <FloatButton.BackTop />
    </div>
  );
}

export default FooterCopyright;