import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Avatar, Button, Drawer, Dropdown, Space } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from "../../hooks/useUsers";
import { auth } from "./../../firebaseConfig";
import { signOut } from "firebase/auth";
import logo from "../../assets/images/logo.png";
import { MenuOutlined, DownOutlined } from "@ant-design/icons";

function AppHeader() {
  function signout() {
    signOut(auth);
    window.location = "/";
  }
  const [user] = useAuthState(auth);
  const { userData } = useUserData(user?.uid);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    !userData?.profileCompleted && {
      label: <Link to="/add-profile">Add Profile</Link>,
      key: "add-profile",
    },
    userData?.profileCompleted && {
      label: <Link to="/edit-profile">Edit Profile</Link>,
      key: "edit-profile",
    },
    userData?.profileCompleted && {
      label: <Link to="/interests">Interests</Link>,
      key: "interests",
    },
    userData?.profileCompleted && {
      label: <Link to="/messenger">Messages</Link>,
      key: "messenger",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to="#0" onClick={signout}>
          Logout{" "}
        </Link>
      ),
      key: "logout",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to="/delete" style={{ color: "red" }}>
          Delete Account
        </Link>
      ),
      key: "delete",
    },
  ];

  return (
    <div className="container">
      {/* header */}
      <div className="header separator">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" height="120" width="250" />
          </Link>
        </div>
        <div className="mobileVisible">
          <Button type="primary" onClick={showDrawer}>
            <MenuOutlined />
          </Button>
          <Drawer placement="right" onClose={onClose} open={visible}>
            <nav>
              <ul>
                {user ? (
                  <>
                    <li key="displayName">
                      <Avatar src={user.photoURL} alt="" size="small" />
                      {user.displayName}
                    </li>
                    {userData?.userRole === "ADMIN" &&
                    userData?.profileCompleted ? (
                      <li key="search">
                        <a href="/search">Search</a>
                      </li>
                    ) : null}
                    {userData?.profileCompleted ? (
                      <li key="interests">
                        <NavLink to="/interests">Interests</NavLink>
                      </li>
                    ) : null}
                    {userData?.profileCompleted ? (
                      <li key="messenger">
                        <NavLink to="/messenger">Messages</NavLink>
                      </li>
                    ) : null}
                    {userData?.profileCompleted ? (
                      <li key="add-profile">
                        <NavLink to="/add-profile">Add Profile</NavLink>
                      </li>
                    ) : null}
                    <li key="signout">
                      <NavLink onClick={signout} to="#0">
                        Signout
                      </NavLink>
                    </li>
                    <li key="delete">
                      <NavLink to="/delete" style={{ color: "red" }}>
                        Delete Account
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li key="login">
                    <Link to="/login">
                      <Button type="primary">Login</Button>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </Drawer>
        </div>
        <nav className="mobileHidden">
          <ul>
            <li></li>
            {user ? (
              <Space size="large">
                {userData?.profileCompleted ? (
                  <li key="suggestions">
                    <a href="/suggestions">Suggestions</a>
                  </li>
                ) : null}
                {userData?.profileCompleted ? (
                  <li key="search">
                    <a href="/search">Search</a>
                  </li>
                ) : null}
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()} href="#0">
                    <Space>
                      <Avatar src={user.photoURL} alt="" size="small" />
                      {user.displayName}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Space>
            ) : (
              <Link to="/login">
                <Button type="default">Login</Button>
              </Link>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default AppHeader;
