import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jsonData from "../../data/data.json";
import { auth } from "../../firebaseConfig";
import {
  Row,
  Col,
  Flex,
  Button,
  Card,
  Form,
  Select,
  InputNumber,
  Drawer,
  Space,
  Spin,
} from "antd";
import Profiles from "./Profiles";
import { FilterOutlined } from "@ant-design/icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from "../../hooks/useUsers";

function Search() {
  let navigate = useNavigate();
  const [user] = useAuthState(auth);
  const { loading, userData } = useUserData(user?.uid);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (userData && !userData.profileCompleted) {
      navigate("/add-profile");
    } else {
      return;
    }
  }, [user, userData, loading, navigate]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [searchData, setSearchData] = useState({
    maritalStatus: "" || null,
    minAge: "" || null,
    maxAge: "" || null,
    haveKids: "" || null,
    wantKids: "" || null,
    education: "" || null,
    employment: "" || null,
    diet: "" || null,
    smoking: "" || null,
    drinking: "" || null,
  });
  const [searchForm] = Form.useForm();

  const handleSelect = (name) => (value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const handleNumberInput = (name) => (value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const handleReset = (e) => {
    searchForm.resetFields();
    setSearchData({
      maritalStatus: "" || null,
      minAge: 0,
      maxAge: 99,
      haveKids: "" || null,
      wantKids: "" || null,
      education: "" || null,
      employment: "" || null,
      diet: "" || null,
      smoking: "" || null,
      drinking: "" || null,
    });
  };

  function showFields() {
    return (
      <div id="feature" className="block">
        <div className="titleHolder">
          <h3>Search Criteria</h3>
        </div>
        <Card bordered={false}>
          <Form
            form={searchForm}
            name="search-profiles"
            layout="vertical"
            autoComplete="off"
          >
            <Row>
              <Col sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Min Age"
                  name="minAge"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber
                    name="minAge"
                    onChange={handleNumberInput("minAge")}
                    value={0}
                    min={0}
                    max={99}
                  />
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Max Age"
                  name="maxAge"
                  style={{ marginBottom: "10px" }}
                >
                  <InputNumber
                    name="maxAge"
                    onChange={handleNumberInput("maxAge")}
                    min={searchData.minAge || 0}
                    max={99}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Marital Status"
              name="maritalStatus"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("maritalStatus")}
                showSearch
                allowClear
                options={jsonData.maritals}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Have Kids"
              name="haveKids"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("haveKids")}
                showSearch
                allowClear
                options={jsonData.haveKids}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Want Kids"
              name="wantKids"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("wantKids")}
                showSearch
                allowClear
                options={jsonData.wantKids}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Education"
              name="education"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("education")}
                showSearch
                allowClear
                options={jsonData.education}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Employment"
              name="employment"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("employment")}
                showSearch
                allowClear
                options={jsonData.employment}
              ></Select>
            </Form.Item>
            <Form.Item
              label="Diet"
              name="diet"
              style={{ marginBottom: "10px" }}
            >
              <Select
                onChange={handleSelect("diet")}
                showSearch
                allowClear
                options={jsonData.diet}
              ></Select>
            </Form.Item>
            <Row>
              <Col sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Smoking"
                  name="smoking"
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    onChange={handleSelect("smoking")}
                    showSearch
                    allowClear
                    options={jsonData.smoking}
                  ></Select>
                </Form.Item>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Drinking"
                  name="drinking"
                  style={{ marginBottom: "10px" }}
                >
                  <Select
                    onChange={handleSelect("drinking")}
                    showSearch
                    allowClear
                    options={jsonData.drinking}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Flex
              type="flex"
              justify="center"
              align="middle"
              gap="small"
              wrap="center"
            >
              <Button type="default" onClick={handleReset} htmlType="button">
                Reset Fields
              </Button>
            </Flex>
          </Form>
        </Card>
      </div>
    );
  }

  return (
    <Spin spinning={loading}>
      <div className="container">
        <Row>
          <Col xs={24}>
            <Row>
              <div className="mobileVisible" style={{ padding: "10px 20px" }}>
                <Space direction="vertical">
                  <Button type="primary" onClick={showDrawer}>
                    <FilterOutlined />
                    Search filter
                  </Button>
                </Space>
                <Drawer
                  title="Search Filters"
                  placement="left"
                  onClose={onClose}
                  open={open}
                >
                  <Col sm={24} md={8} xl={6} style={{ padding: "20px" }}>
                    {showFields()}
                  </Col>
                </Drawer>
              </div>
              <Col sm={24} md={8} xl={6} className="mobileHidden">
                {showFields()}
              </Col>
              <Col sm={24} md={16} xl={18} style={{ padding: "10px 20px" }}>
                <Profiles searchCriteria={searchData} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}

export default Search;
