import React from 'react'
import { Spin } from 'antd'
import useProfileStatus from '../../hooks/useProfileStatus'
import { Outlet, Navigate } from 'react-router-dom'

const ProfileRoute = (props) => {
  
  const { loading, isProfileCompleted } = useProfileStatus()  
    
  if(loading) {
      return <Spin />
  }
  return isProfileCompleted ? <Outlet /> : <Navigate to={props.route} />
}

export default ProfileRoute