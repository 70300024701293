import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth, storage } from "../../firebaseConfig";
import { updateProfile } from "firebase/auth";
import { useUserData } from "../../hooks/useUsers";
import jsonData from "../../data/data.json";
import dayjs from "dayjs";
import maleLogo from "../../assets/images/icons8-man-in-tuxedo-48.png";
import femaleLogo from "../../assets/images/icons8-female-48.png";
import kidsLogo from "../../assets/images/icons8-kids-40.png";
import noKidsLogo from "../../assets/images/icons8-no-kids-50.png";
import { useNavigate } from "react-router-dom";
import {
  OrderedListOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Flex,
  Drawer,
  Menu,
  Form,
  Card,
  Row,
  Col,
  Typography,
  Input,
  Divider,
  Radio,
  Checkbox,
  Select,
  Button,
  Space,
  message,
  DatePicker,
  Upload,
  Progress,
  Spin,
  Tooltip,
} from "antd";
import { v4 as uuidv4 } from "uuid";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const { Title } = Typography;
const { TextArea } = Input;

function EditProfile() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();
  const [selectedMenuItem, setSelectedMenuItem] = useState("basic");
  const [user] = useAuthState(auth);
  const { loading: userDataLoading, userData } = useUserData(user?.uid);
  const [visible, setVisible] = React.useState(false);
  // const [formData, setFormData] = useState({});

  React.useEffect(() => {
    if (userDataLoading) return;
    if (userData && !userData.profileCompleted) {
      navigate("/add-profile");
    } else {
      return;
    }
  }, [user, userData, userDataLoading, navigate]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const initialValues = [
    {
      name: ["profileId"],
      value: userData?.profileId,
    },
    {
      name: ["firstName"],
      value: userData?.firstName,
    },
    {
      name: ["gender"],
      value: userData?.gender,
    },
    {
      name: ["dateOfBirth"],
      value: dayjs(userData?.dateOfBirth.toDate()),
    },
    {
      name: ["maritalStatus"],
      value: userData?.maritalStatus,
    },
    {
      name: ["currentResidence"],
      value: userData?.currentResidence,
    },
    {
      name: ["citizenOf"],
      value: userData?.citizenOf,
    },
    {
      name: ["languages"],
      value: userData?.languages,
    },
    {
      name: ["ethnicity"],
      value: userData?.ethnicity,
    },
    {
      name: ["religion"],
      value: userData?.religion,
    },
    {
      name: ["zodiacSign"],
      value: userData?.zodiacSign,
    },
    {
      name: ["politics"],
      value: userData?.politics,
    },
    {
      name: ["education"],
      value: userData?.education,
    },
    {
      name: ["schoolName"],
      value: userData?.schoolName,
    },
    {
      name: ["employer"],
      value: userData?.employer,
    },
    {
      name: ["jobTitle"],
      value: userData?.jobTitle,
    },
    {
      name: ["coffeeFlavors"],
      value: userData?.coffeeFlavors,
    },
    {
      name: ["haveKids"],
      value: userData?.haveKids,
    },
    {
      name: ["wantKids"],
      value: userData?.wantKids,
    },
    {
      name: ["havePets"],
      value: userData?.havePets,
    },
    {
      name: ["haveDebt"],
      value: userData?.haveDebt,
    },
    {
      name: ["haveCar"],
      value: userData?.haveCar,
    },
    {
      name: ["smoking"],
      value: userData?.smoking,
    },
    {
      name: ["tobacco"],
      value: userData?.tobacco,
    },
    {
      name: ["drinking"],
      value: userData?.drinking,
    },
    {
      name: ["marijuana"],
      value: userData?.marijuana,
    },
    {
      name: ["diet"],
      value: userData?.diet,
    },
    {
      name: ["height"],
      value: userData?.height,
    },
    {
      name: ["bodyType"],
      value: userData?.bodyType,
    },
    {
      name: ["disability"],
      value: userData?.disability,
    },
    {
      name: ["profileImages"],
      value: userData?.profileImages,
    },
    {
      name: ["aboutMe"],
      value: userData?.aboutMe,
    },
    {
      name: ["lookingFor"],
      value: userData?.lookingFor,
    },
  ];

  const storageImage = async (img) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/images/${Date.now()}-${uuidv4()}`);
      const uploadImage = uploadBytesResumable(storageRef, img.originFileObj);

      uploadImage.on(
        "state_changed",
        (snapshot) => {
          const progressPercent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressPercent);
        },
        (error) => {
          reject(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
            resolve({ uid: uuidv4(), url: downloadURL });
          });
        }
      );
    });
  };

  const handleFinish = async (values) => {
    console.log(values);

    var profileImages = [];
    if (selectedMenuItem === "photos") {
      profileImages = await Promise.all(
        [...values.profileImages].map((image) => {
          if (image.url) {
            return image;
          } else {
            return storageImage(image);
          }
        })
      ).catch((error) => {
        alert(error);
        return;
      });
    }

    setLoading(true);
    try {
      const profileDoc =
        selectedMenuItem === "basic"
          ? {
            profileId: values.profileId,
            firstName: values.firstName,
            gender: values.gender,
            maritalStatus: values.maritalStatus,
            currentResidence: values.currentResidence,
            citizenOf: values.citizenOf,
            languages: values.languages,
          }
          : selectedMenuItem === "background"
            ? {
              ethnicity: values.ethnicity,
              religion: values.religion,
              zodiacSign: values.zodiacSign,
              politics: values.politics,
              education: values.education,
              schoolName: values.schoolName,
              jobTitle: values.jobTitle || "",
              employer: values.employer || "",
            }
            : selectedMenuItem === "coffee"
              ? {
                coffeeFlavors: values.coffeeFlavors,
              }
              : selectedMenuItem === "family"
                ? {
                  haveKids: values.haveKids,
                  wantKids: values.wantKids,
                  havePets: values.havePets,
                  haveCar: values.haveCar,
                  haveDebt: values.haveDebt,
                }
                : selectedMenuItem === "general"
                  ? {
                    smoking: values.smoking,
                    tobacco: values.tobacco,
                    drinking: values.drinking,
                    marijuana: values.marijuana,
                    diet: values.diet,
                    height: values.height,
                    bodyType: values.bodyType,
                    disability: values.disability,
                  }
                  : selectedMenuItem === "photos"
                    ? {
                      profileImages: profileImages,
                    }
                    : selectedMenuItem === "aboutMe"
                      ? {
                        aboutMe: values.aboutMe,
                      }
                      : selectedMenuItem === "lookingFor"
                        ? {
                          lookingFor: values.lookingFor,
                        }
                        : null;

      const userRef = doc(db, "users", user.uid);

      updateDoc(userRef, profileDoc)
        .then(() => {
          updateProfile(auth.currentUser, {
            displayName: values.firstName,
          });
          setLoading(false);
          message.success(`Information updated successfully.`, 2);
        })
        .catch((err) => {
          console.log(err);
          message.error(`Profile could not be created.`, 2);
        });

      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 1000);
    } catch (error) { }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} lg={6} xl={6}>
            <div className="mobileHidden">
              <div id="feature" className="block">
                <div className="titleHolder">
                  <h3>Tabs</h3>
                </div>

                <Menu
                  selectedKeys={selectedMenuItem}
                  mode="vertical"
                  onClick={(e) => setSelectedMenuItem(e.key)}
                >
                  <Menu.Item key="basic">Basic Information</Menu.Item>
                  <Menu.Item key="background">Background Information</Menu.Item>
                  <Menu.Item key="general">General Information</Menu.Item>
                  <Menu.Item key="coffee">Coffee Flavors</Menu.Item>
                  <Menu.Item key="family">Family Information</Menu.Item>
                  <Menu.Item key="photos">Photos Information</Menu.Item>
                  <Menu.Item key="aboutMe">About Self</Menu.Item>
                  <Menu.Item key="lookingFor">About Partner</Menu.Item>
                </Menu>
              </div>
            </div>
            <div className="mobileVisible">
              <Button type="primary" onClick={showDrawer}>
                <OrderedListOutlined />
              </Button>
              <Drawer placement="left" onClose={onClose} open={visible}>
                <div className="titleHolder">
                  <h3>Tabs</h3>
                </div>
                <Col sm={24}>
                  <Menu
                    selectedKeys={selectedMenuItem}
                    mode="vertical"
                    onClick={(e) => setSelectedMenuItem(e.key)}
                  >
                    <Menu.Item key="basic">Basic Information</Menu.Item>
                    <Menu.Item key="background">
                      Background Information
                    </Menu.Item>
                    <Menu.Item key="general">General Information</Menu.Item>
                    <Menu.Item key="coffee">Coffee Flavors</Menu.Item>
                    <Menu.Item key="family">Family Information</Menu.Item>
                    <Menu.Item key="photos">Photos Information</Menu.Item>
                    <Menu.Item key="aboutMe">About Self</Menu.Item>
                    <Menu.Item key="lookingFor">About Partner</Menu.Item>
                  </Menu>
                </Col>
              </Drawer>
            </div>
          </Col>
          <Col xs={24} lg={18} xl={18}>
            <div id="feature" className="block">
              <div className="titleHolder">
                <h3>Edit Profile</h3>
              </div>
              {selectedMenuItem === "basic" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>Basic Information</Title>}>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item name="profileId" hidden>
                            <Input hidden name="profileId" />
                          </Form.Item>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please input First Name",
                              },
                            ]}
                          >
                            <Input name="firstName" placeholder="First Name" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Gender" name="gender">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="gender"
                            >
                              <Radio value="Male">
                                {" "}
                                <Space>
                                  <img src={maleLogo} width="20px" alt="Male" />
                                  Male
                                </Space>
                              </Radio>
                              <Radio value="Female">
                                {" "}
                                <Space>
                                  <img
                                    src={femaleLogo}
                                    width="20px"
                                    alt="Female"
                                  />
                                  Female
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marital Status"
                            name="maritalStatus"
                            rules={[
                              {
                                required: true,
                                message: "Your marital status",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Marital Status"
                              showSearch
                              options={jsonData.maritals}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Date of Birth" name="dateOfBirth">
                            <DatePicker disabled name="dateOfBirth" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Current residence"
                            name="currentResidence"
                          >
                            <Input
                              name="currentResidence"
                              placeholder="Residing city"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Citizen of" name="citizenOf">
                            <Select
                              placeholder="Citizen Of"
                              showSearch
                              options={jsonData.currencies}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Languages you speak"
                            name="languages"
                          >
                            <Select
                              mode="multiple"
                              placeholder="Languages you speak"
                              showSearch
                              options={jsonData.languages}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Basic
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "coffee" ? (
                <Col xs={24} lg={24} xl={24}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 700,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>What type of Coffee do you like?</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={12} lg={24} xl={24}>
                          <Form.Item
                            name="coffeeFlavors"
                            rules={[
                              {
                                validator(_, value) {
                                  console.log("value ", value);
                                  if (value.length < 3) {
                                    return Promise.reject(
                                      new Error("You must select at least 3 flavors")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}>
                            <Checkbox.Group
                              style={{
                                width: "100%",
                              }}
                            >
                              <Row gutter={[16, 24]}>
                                {jsonData.coffeeFlavors.map((flavor) => (
                                  <Col key={flavor.key} xs={12} lg={8} xl={8}>
                                    <Checkbox value={flavor.value}>
                                      <Tooltip title={flavor.text} color="orange" key={flavor.key}>
                                        {flavor.value}
                                      </Tooltip>
                                    </Checkbox>
                                  </Col>
                                ))}
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Coffee Choices
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "background" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="religious"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card
                      title={<Title level={5}>Background Information</Title>}
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Ethnicity"
                            name="ethnicity"
                            rules={[
                              {
                                required: true,
                                message: "Ethnicity",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Ethnicity"
                              showSearch
                              options={jsonData.ethnicity}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Politics"
                            name="politics"
                            rules={[
                              {
                                required: true,
                                message: "Politics",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Politics"
                              showSearch
                              options={jsonData.politics}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Religion"
                            name="religion"
                            rules={[
                              {
                                required: true,
                                message: "Religion",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Religion"
                              showSearch
                              options={jsonData.religion}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Zodiac Sign" name="zodiacSign">
                            <Select
                              placeholder="zodiacSign"
                              showSearch
                              options={jsonData.zodiacSign}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education"
                            name="education"
                            rules={[
                              {
                                required: true,
                                message: "Education",
                              },
                            ]}
                          >
                            <Select
                              placeholder="education"
                              showSearch
                              options={jsonData.education}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="School/University"
                            name="schoolName"
                          >
                            <Input name="schoolName" placeholder="School / University" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Job Title" name="jobTitle">
                            <Input name="jobTitle" placeholder="jobTitle" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Employer Name" name="employer">
                            <Input name="employer" placeholder="employer" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Background
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "family" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="family"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card
                      title={
                        <Title level={5}>Family/Finance Information</Title>
                      }
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Kids " name="haveKids">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveKids"
                            >
                              <Radio value="Yes">
                                {" "}
                                <Space>
                                  <img src={kidsLogo} width="20px" alt="Kids" />
                                  Yes
                                </Space>
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <Space>
                                  <img
                                    src={noKidsLogo}
                                    width="20px"
                                    alt="No Kids"
                                  />
                                  No
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Want Kids " name="wantKids">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="wantKids"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Pets " name="havePets">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="havePets"
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider orientation="left">Finance</Divider>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have a Car " name="haveCar">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveCar"
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Have Debt " name="haveDebt">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveDebt"
                            >
                              <Radio value="Yes">
                                {" "}
                                <CheckOutlined />
                                Yes
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <CloseOutlined />
                                No{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Family
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "general" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="general"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>General Information</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Smoking"
                            name="smoking"
                            rules={[
                              {
                                required: true,
                                message: "Smoking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="smoking"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Tobacco"
                            name="tobacco"
                            rules={[
                              {
                                required: true,
                                message: "Tobacco Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="tobacco"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Drinking"
                            name="drinking"
                            rules={[
                              {
                                required: true,
                                message: "Drinking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="drinking"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Socially"> Socially</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marijuana"
                            name="marijuana"
                            rules={[
                              {
                                required: true,
                                message: "Marijuana Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="marijuana"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Sometimes"> Sometimes </Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Dietary Habits"
                            name="diet"
                            rules={[
                              {
                                required: true,
                                message: "Dietary Habits",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.diet}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={6} xl={6}>
                          <Form.Item label="Height" name="height">
                            <Select
                              showSearch
                              options={jsonData.height}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Body Type"
                            name="bodyType"
                            rules={[
                              {
                                required: true,
                                message: "Body Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.bodyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Disability" name="disability">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="disability"
                            >
                              <Radio value="Normal"> Normal</Radio>
                              <Radio value="Physically Challenged">
                                Physically Challenged
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update General
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "photos" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>Photos</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            name="profileImages"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload one photo",
                              },
                            ]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              listType="picture-card"
                            >
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button"
                              >
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                              </button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Photos
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "aboutMe" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>About Self</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Self"
                            name="aboutMe"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about yourself",
                              },
                            ]}
                          >
                            <TextArea showCount name="aboutMe" rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update About Self
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "lookingFor" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>About Partner</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Partner"
                            name="lookingFor"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about partner",
                              },
                            ]}
                          >
                            <TextArea showCount name="lookingFor" rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update About Partner
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : null}
              <Row
                gutter={[16, 16]}
                type="flex"
                justify="center"
                align="middle"
                style={{ marginBottom: 8 }}
              >
                <Col className="gutter-row" xs={24} lg={12} xl={12}>
                  {progress === 0 ? null : <Progress percent={progress} />}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default EditProfile;
